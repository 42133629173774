import * as React from 'react';
import { navigate } from 'gatsby';

import Layout from './Layout';


const AdminIndex = ({ data }) => {

    React.useEffect(() => {
        navigate('pages');
    }, []);

    return (
        <Layout style={{ fontFamily: 'monospace' }}>
            <p>Loading...</p>
        </Layout>
    )
}

export default AdminIndex;